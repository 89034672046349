import type { QueryParams } from '~/types/api'
import type {
  Country,
  CountryPagedResult,
  Region,
  RegionPagedResult,
} from '~/types/api'

export function createCountriesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN_COUNTRIES = 'countries'
  const DOMAIN_REGIONS = 'regions'

  async function getAll(params?: QueryParams) {
    return get<CountryPagedResult>([DOMAIN_COUNTRIES], { params })
  }

  async function getByNid(nid: number) {
    return get<Country>([DOMAIN_COUNTRIES, nid.toString()])
  }

  async function getAllRegions(params?: QueryParams) {
    return get<RegionPagedResult>([DOMAIN_REGIONS], { params })
  }

  async function getRegion(id: number) {
    return get<Region>([DOMAIN_REGIONS, id.toString()])
  }

  return {
    getAll,
    getByNid,
    getAllRegions,
    getRegion,
  }
}
